@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

$icon-space: 6px;

.container {
  border-bottom: 1px solid $color-gray;
  margin-top: $space-sm;
  width: 100%;
  padding-bottom: $space-xs;
}

.header {
  display: flex;
  flex-direction: column;
  margin-bottom: $space-xxs;
}

.title {
  margin: $space-sm 0 0 0;
  letter-spacing: 1px;
  font-size: $font-size-base;
}

.ratingContainer {
  display: flex;
  gap: $space-xxs;
  align-items: center;
  line-height: 1;
}

.rating {
  font-size: $font-size-base;
}

.content {
  display: flex;
  flex-direction: column;
  margin-bottom: $space-sm;
  width: 100%;
}

.textContent {
  margin: 0 0 $space-sm;
  width: 100%;
  font-size: $font-size-base;

  @include md-min {
    max-width: 80%;
    width: 100%;
  }
}

.submittedDate {
  margin-bottom: 0;
}

.author {
  display: flex;
  flex-direction: column;

  span {
    display: block;
    margin-bottom: $space-xxxs;
  }
}

.reviewHelpful {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.voteWrapper {
  display: flex;
  margin: 0 $space-xs;
}

.vote {
  background: transparent;
  padding: $space-xxs $space-xxxs;
  display: flex;
  align-items: center;
}

.voted {
  svg {
    color: $color-blue;
  }
}

.icon {
  margin-right: $icon-space;
}
