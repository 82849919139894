@use "sass:math";
@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.container {
  display: flex;
  flex-direction: column;
  max-width: 1920px;
  margin: 16px auto;
  padding: 0 $space-xs;

  @include lg-min {
    width: 95%;
    padding: 0 48px;
  }

  .tilesContainer {
    display: flex;
    justify-content: center;
  }

  .tileContainer {
    width: 25%;
    margin: 0;
  }

  &.grid {
    .tilesContainer {
      flex-wrap: wrap;
    }
  }

  &.slide {
    .tilesContainer {
      overflow-y: hidden;
      overflow-x: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      justify-content: flex-start;
      column-gap: 0;

      @include md-min {
        column-gap: $space-xs;
      }

      &::-webkit-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        display: none;
      }
    }

    .tileContainer {
      min-width: 60%;
    }

    @include md-min {
      .tilesContainer {
        overflow-x: visible;
        overflow-y: visible;
      }

      .tileContainer {
        min-width: fit-content;
      }
    }
  }
}

.title {
  font-family: $heading-font;
  font-size: $font-size-md;
  font-weight: $font-weight-light;

  text-align: center;
  text-transform: uppercase;
  letter-spacing: $letter-spacing-headline;

  padding-bottom: 22px;
}
